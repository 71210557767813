.login-form {

  &__inputs {
    display: grid;
    // gap: 10px;
  }
  a,
  span {
    color: #f00;
    font-size: 12px;
    font-family: Raleway;
    letter-spacing: 0.28px;
    text-align: right;
    margin-top: 10px;
    text-decoration: none;
    display: block;
  }
  .login-button {
    margin-top: 1.5rem;
    padding: 1.8rem 0;
  }
}
