@import "components/ui/__variables";

.stats-card {
  padding: 24px;

  align-items: center;

  border: 1px solid $card-border-color;
  border-radius: 10px;

  background: $white-color;

  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.02);

  //   width: 322px;

  // width: 100%;

  height: fit-content;

  @media screen and (max-width: $large-screen) {
    padding: 15px 10px;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #3498db;
  }

  &__bottom {
    display: flex;

    align-items: center;

    width: 100%;

    height: fit-content;

    margin-top: 24px;
    p {
      font-size: 30px;
      margin-right: 5px;
      margin-top: -0.5rem;
      margin-bottom: -0.5rem;
      color: #3498db;
    }
  }

  h3 {
    color: $gray-muted-variant-color;

    text-align: center;

    font-size: 14px;

    font-family: Raleway;

    font-weight: 500;

    margin-top: 0;

    margin-bottom: 0;

    @media screen and (max-width: $large-screen) {
      font-size: 9px;

      font-style: normal;

      font-weight: 500;

      line-height: normal;
    }
  }

  b {
    color: #3498db;

    font-size: 24px;

    // font-family: Raleway;

    font-weight: 600;

    letter-spacing: -1px;

    margin-top: 0;

    @media screen and (max-width: $large-screen) {
      font-size: 24px;

      font-style: normal;

      font-weight: 600;

      line-height: normal;

      letter-spacing: -1px;
    }
  }

  span {
    display: flex;

    padding: 7px;

    gap: 5px;

    border-radius: 3px;

    background: $yellow-color;

    color: $black-color;

    font-size: 10px;

    height: 12px;

    font-family: Raleway;

    align-items: center;

    max-width: 150px;

    width: fit-content;

    @media screen and (max-width: $large-screen) {
      font-size: 8px;

      font-weight: 400;

      line-height: normal;

      max-width: 100px;
    }
  }
}
