@import "components/ui/__variables";

.project-list {
  gap: 7px;
  height: fit-content;
  max-height: 80vh;
  overflow: auto;

  display: grid;
  .search {
    width: 100%;
  }

  .cards {
    max-height: 70vh;

    height: fit-content;

    overflow: scroll;
    gap: 7px;
    margin-top: 1rem;
  }

  &__header {
    display: none;

    span {
      color: rgba(7, 25, 57, 0.87);

      font-family: DM Sans;

      font-size: 16px;

      font-style: normal;

      font-weight: 600;

      line-height: normal;
    }

    @media screen and (max-width: $medium-screen) {
      display: flex;

      align-items: center;

      position: fixed;

      justify-content: space-between;

      margin-top: -2rem;

      width: inherit;

      right: 30px;

      left: 30px;
    }
  }

  .cancel {
    cursor: pointer;

    right: 0px;

    @media screen and (min-width: $medium-screen) {
      display: none;
    }
  }

  &__cards {
    overflow-y: scroll;

    display: grid;

    gap: 7px;

    bottom: 0;

    @media screen and (max-width: $medium-screen) {
      padding-bottom: 2rem;
    }
  }

  @media screen and (max-width: $medium-screen) {
    padding: 2rem;

    padding-top: 2.5rem;

    margin-right: auto;

    margin-left: auto;

    left: 0;

    right: 0;

    background: $white-primary-variant !important;

    z-index: 1000;

    bottom: 0px;

    height: 35vh;

    overflow: unset;

    display: none;

    border: 1px solid $primary-color;

    position: fixed;

    z-index: 100000;

    &.show {
      display: grid;
    }
  }
}
