.linear-progress-bar {
  display: flex;
  width: 120px;
  border-radius: 4px;
  background: #e9ecee;
  height: 18px;

  &__progress {
    background-color: #008ee2;
    height: 100%;
    width: 0%;
    border-radius: 4px;
    display: flex;
    color: #fff;
    align-items: center;
    justify-content: center;
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.154px;
  }
}
