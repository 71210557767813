@import "components/ui/Main";

.auth-layout {
  width: 100%;

  height: 100vh;

  background-color: $white-muted-color;

  display: grid;

  grid-template-columns: 1fr 1fr;

  .logo {
    cursor: pointer;
    margin-top: -2.5rem;
  }

  @media screen and (max-width: $medium-screen) {
    grid-template-columns: 1fr;

    display: block;
    // height: 100%;
    min-height: 100vh;
  }

  &__left {
    padding: 70px 39px;

    &--image {
      height: 100%;

      display: flex;

      justify-content: center;

      align-items: center;

      svg {
        width: 100%;

        height: 100%;
      }

      @media screen and (max-width: $small-screen) {
        display: none;
      }
    }

    @media screen and (max-width: $medium-screen) {
      padding-left: 0;

      padding-right: 0;

      padding: 0;

      padding-top: 12px;

      padding-bottom: 12px;

      text-align: center;

      background-color: #008ee21a;

      .logo {
        svg {
          width: 154px;
          height: 36px;
        }
      }
    }
  }

  &__right {
    background-color: $white-color;

    padding-left: 76px;

    padding-right: 76px;

    display: flex;

    justify-content: center;

    align-items: center;

    &__container {
      width: 100%;

      @media screen and (min-width: $medium-screen) {
        max-height: 100vh;

        overflow-y: scroll;
      }
    }

    @media screen and (max-width: $medium-screen) {
      padding-left: 16px;

      padding-right: 16px;

      min-height: 60vh;

      margin-left: 16px;

      margin-right: 16px;

      margin-top: 32px;

      padding-top: 20px;
    }
  }
  .main {
    width: 60%;
    margin-top: 2rem;
    @media screen and (max-width: "1200px") {
      width: 70%;
    }
    @media screen and (max-width: $small-screen) {
      width: 90%;
    }
  }
}
