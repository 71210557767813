@import "../_variables";

.text-input {
  width: 100%;

  .label {
    color: $black-color;

    display: block;

    color: var(--darkest, #071939);

    font-size: 14px;

    font-family: Raleway;

    font-weight: 500;
  }

  .input {
    outline: none;
    height: $input-height;
    margin-top: 4px;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    border-radius: $border-radius;
    display: block;
    line-height: 25px;
    -webkit-appearance: none;
    border: 1px solid $white-muted-color;
    background: $white-color;
    transition: border 0.3s ease;

    height: 55px;
    padding: 8px 11px;
    align-items: center;
    align-self: stretch;
    border: 1px solid var(--light-grey, #e9e9f0);
    background: var(--white, #fff);

    &::placeholder {
      color: $white-muted-color;
    }
    &:focus {
      outline: none;
      border-color: $primary-color;
    }

    &::placeholder {
      color: $gray-muted-color;
    }
    &-invert {
      border: 1px solid $primary-color;
    }
    &-invert:focus {
      outline: none;
      border-color: $white-muted-color;
    }
    &-error {
      color: $cancel-color;
      font-size: 0.8rem;
    }
    &-error-input {
      border: 0.5px solid $cancel-color !important;
    }
    &-error-input:focus {
      border: 0.5px solid $cancel-color;
    }
  }
  .input_div {
    position: relative;
  }
  .end_icon_1 {
    position: absolute;
    right: 3%;
    bottom: 20%;
    width: 25px;
    cursor: pointer;
  }

  .end_icon_2 {
    position: absolute;
    right: 3%;
    bottom: 20%;
    width: 25px;
    cursor: pointer;
  }
}

