$color: #2b5da1;
$primary-color: #008ee2;
$primary-muted-color: #008fe3;
$primary-variant-color: #2b5da1;
$secondary-color: #002c61;
$white-color: white;
$black-color: #000000;
$black-muted-color: #5a5a5a;
$button-width: 100%;
$button-height: 50px;
$button-radius: 8px;
$input-height: 38px;
$textarea-height: 7rem;
$textarea-width: 100%;
$cancel-color: #c50707;
$gray-color: #a6a9ae;
$gray-muted-color: #3c4257;
$white-muted-color: #e0e6eb;
$border-radius: 3px;
$card-color: #050211d2;
$small-screen: 480px;
$medium-screen: 769px;
$large-screen: 1280px;
$extra-large-screen: 1280px;
$visa-card-color: #00579f;
$brown-color: #414552;
$purple-color: #635bff;
$green-color: #006908;
$violet-color: #6a7383;
$violet-muted-color: #687385;
$white-variant-color: #ebebeb;
$white-variant-muted-color: #fafafa;
$white-primary-diluted-color: #e7f6ff;
$white-primary-variant: #f1f4f9;
$card-border-color: #ececee;
$border-color:#ececee;
$gray-muted-variant-color: #57595b;
$black-muted-variant-color: #2a2a2a;
$yellow-color: #f79256;

:export {
  color: $color;
  primary-color: $primary-color;
  secondary-color: $secondary-color;
  button-width: $button-width;
  button-height: $button-height;
  white-color: $white-color;
  black-color: $black-color;
  input-height: $input-height;
  textarea-height: $textarea-height;
  textarea-width: $textarea-width;
  cancel-color: $cancel-color;
  gray-color: $gray-color;
  primary-muted-color: $primary-muted-color;
  white-muted-color: $white-muted-color;
  gray-muted-color: $gray-muted-color;
  card-color: $card-color;
  primary-variant-color: $primary-variant-color;
  medium-screen: $medium-screen;
}
