@import "components/ui/__variables";

.project-overview {
  padding: 21px 14px;

  background-color: $white-color;

  @media screen and (max-width: $medium-screen) {
    margin-top: 0px;
  }

  h2 {
    color: $black-color;
    font-family: Raleway;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.4px;
    margin-top: 0;
    margin-bottom: 12px;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 2px solid #3498DB;
    .actions {
      display: flex;
      gap: 12px;
      margin-bottom: 1rem;
      button.delete {
        background: #e9e9f0;
        border: none;
      }
    }
  }

  .project_body {
    overflow: scroll;

    max-height: 60vh;
  }
}
