@import "components/ui/__variables";

.stats-cards-list {
  display: grid;

  grid-template-columns: 1fr 1fr 1fr 1fr;

  gap: 10px;

  margin-bottom: 1rem;

  @media screen and (max-width: $large-screen) {
    grid-template-columns: 1fr 1fr;
  }

  &.two {
    grid-template-columns: 1fr 1fr;
  }

  &.three {
    grid-template-columns: 1fr 1fr 1fr;
    @media screen and (max-width: $medium-screen) {
      grid-template-columns: 1fr;
    }
  }
  &.four {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media screen and (max-width: $large-screen) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &.five {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
