@import "components/ui/__variables";

.perf-effectiveness {
  //   width: 100%;
  border: 1px solid var(--colors-gray-200, #ececee);
  background: #fff;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.05);
  padding: 12px 30px;
  @media screen and (max-width: $medium-screen) {
    padding-left: 20px;
    margin-bottom: 12px;
    margin-top: 12px;
  }
  h3 {
    color: var(--primary-dark-1, #242731);
    font-family: DM Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 18px */
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 30px;
    gap: 20px;
    @media screen and (max-width: $medium-screen) {
      padding-right: 20px;
    }
  }

  &__perf-card {
    max-width: 228px;
    width: 100%;
    // height: 80px;
    background: #f4f5f9;
    padding: 8px 14px;
    padding-bottom: 17px;
    &__items {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      div {
        height: fit-content;
        width: 100%;
        &:last-child {
          span {
            background: #008ee2;
          }
        }
      }
    }

    h6 {
      color: var(--primary-dark-1, #242731);
      font-family: DM Sans;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    h5 {
      color: #000;
      font-family: DM Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 9px;
      margin-bottom: 2px;
    }

    section {
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        color: #000;
        font-family: DM Sans;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 0px;
        margin-bottom: 0px;
      }

      span {
        width: 11px;
        height: 11px;
        background: #002bb4;
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    border-top: 1px solid #e9ecee;
    margin-top: 17px;
    padding-top: 13px;
    padding-bottom: 13px;
    gap: 12px;
    div.icon {
      display: flex;
      width: 39px;
      height: 39px;
      //   padding: 9px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 5px;
      background: rgba(224, 241, 251, 0.5);
    }
    h4 {
      color: #000;
      font-family: Raleway;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 15px */
      margin-top: 0;
      margin-bottom: 0;
    }
    section {
      display: flex;
      gap: 12px;
      margin-top: 9px;
    }
    span.days {
      display: flex;
      height: 21px;
      padding: 6px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      background: #d1eeff;
      color: #000;
      font-family: DM Sans;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      width: 32px;
    }

    span.ratings {
      display: flex;
      padding: 6px;
      justify-content: center;
      align-items: center;
      gap: 2px;
      border-radius: 6px;
      background: #fff0bb;
      width: 42px;
      div {
        gap: 0px !important;
      }
      i {
        color: #ffac33;
        font-size: 9px !important;
        gap: 0 !important;
        margin-left: 0px !important;
      }
    }
  }
}
