@import "components/ui/__variables";

.tasks-breakdown {
  border: 1px solid $card-border-color;
  background: $white-color;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.05);
  // max-width: 220px;
  // width: 100%;
  //   height: 263px;
  padding: 24px;

  @media screen and (max-width: $medium-screen) {
    padding: 20px;
  }

  div.icon {
    border-radius: 5px;
    background: $primary-muted-color;
    box-shadow: 0px 0px 0px 1px rgba(41, 49, 56, 0.16) inset;
    display: flex;
    padding: 9px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 28px;
    height: 28px;
    margin-top: 28px;
    margin-bottom: 9px;
  }

  h3 {
    color: $black-color;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-top: 0;
    margin-bottom: 0;
  }

  h5 {
    color: $black-color;
    font-family: Raleway;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 0;
    margin-bottom: 12px;
  }

  div.task-done {
    display: flex;
    min-width: 124px;
    padding: 3px 11px;
    gap: 10px;
    border-radius: 5px;
    background: #d4f8d3;
    align-items: center;
    width: fit-content;
    span {
      color: $black-color;
      font-family: Raleway;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
    }
    b {
      color: $black-color;
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }
    &:last-child {
      border-radius: 5px;
      background: #fbe7e9;
      margin-top: 12px;
    }
  }
}
