@import "components/ui/__variables";

.dteam-overview {
  height: 127px;
  padding: 24px;
  //   width: 200px;
  border: 1px solid $card-border-color;
  background: $white-color;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Raleway;
  @media screen and (min-width: $large-screen) {
    // max-width: 180px;
  }
  
  &__layer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  b {
    color: $black-color;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.32px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    color: #646469;
    font-family: Raleway;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    &.team-banner {
      width: 100%;
      height: 38px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      padding: 0.2rem 0.5rem;
      background: var(--light-accent, #4a49cb);
      color: $white-color;
      display: flex;
      text-align: center;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }

    &.team-count {
      display: flex;
      padding: 7px;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      background: $primary-muted-color;
      color: $white-color;
      min-width: 12px;
      font-weight: bold;
    }

    &.name {
      color: $black-color;
      font-family: Raleway;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
