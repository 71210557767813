@import "../_variables";

.empty-view {
  text-align: center;
  &__icon {
    font-size: 5rem;
    margin-top: 2rem;
    color: $primary-muted-color;
    margin-bottom: 1rem;
  }
  p {
    color: $gray-color;
    line-height: 2rem;
  }
  button {
    width: fit-content !important;
    margin-left: auto;
    margin-right: auto;
  }
}
