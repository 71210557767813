@import "components/ui/__variables";

.dev-analysis {
//   width: 250px;
  height: 318px;
  border: 1px solid $border-color;
  background: $white-color;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.05);
  padding: 24px;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      color: $black-color;
      font-family: Raleway;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }
    span {
      color: $primary-color;
      font-family: Raleway;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.2px;
    }
  }
  &__category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border-color;
    padding: 10px 0;
    span.category {
      color: $black-color;
      font-family: Raleway;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    div {
      display: flex;
      padding: 6px 11px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      color: #000;
      font-family: Raleway;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      &.low {
        background: #fbe7e9;
      }
      &.very-high {
        background: #d1eeff;
      }
      &.high {
        background: #d4f8d3;
      }
    }
  }
}
