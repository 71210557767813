@import "components/ui/Main";

.auth-footer {
  a {
    text-decoration: none !important;
    font-weight: 550;
  }
  p {
    color: $black-muted-color;

    font-size: 16px;

    font-family: Raleway;

    text-align: center;

    &.terms {
      @media screen and (max-width: $medium-screen) {
        margin-top: 0px;
      }
      span, a {
        text-decoration: underline #008ee2 !important;
        color: #008ee2;
        font-weight: 600;
        margin-right: 0.3rem;
        cursor: pointer;
      }
    }
  }

  p:last-child {
    color: $black-muted-color;

    text-align: center;

    font-size: 14px;

    font-family: Raleway;

    line-height: 25px;
  }

  h3 {
    color: #666e80;

    font-family: Nunito;

    font-size: 10px;

    font-style: normal;

    font-weight: 500;

    line-height: normal;

    margin-top: 50px;

    @media screen and (max-width: $medium-screen) {
      margin-top: 20px;
    }
  }
}
