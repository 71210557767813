@import "components/ui/__variables";

.create-project-form {
  margin-top: 1.5rem;
}
.project_form_items {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .item {
    width: 48%;
    margin-bottom: 1rem;
  }
  .item_ {
    width: 48%;
    margin-top: 0.3rem;
    margin-bottom: 1rem;
  }
}
.admin-project_btn {

  button {
    height: 45px !important;
    padding: 6px 12px !important;
    align-items: center;
    border-radius: 8px !important;
    font-family: DM Sans;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 14px !important;
    margin: 1rem 0;
    width: 80%;
  }

  &__button {
    width: 102px !important;
    height: 45px !important;
    padding: 6px 12px !important;
    margin-left: auto;
    color: #071939;
    font-family: DM Sans;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;
  }
}
