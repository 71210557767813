@import "components/ui/__variables";

.admin-kpi-modal-cards {
  margin-bottom: 3rem;
  @media screen and (max-width: $large-screen) {
    // width: 30rem !important;
  }

  @media screen and (max-width: $medium-screen) {
    grid-template-columns: 1fr;
    display: block;
  }
  &__next {
    width: 100%;
    // gap: 20px;
    @media screen and (max-width: $medium-screen) {
      display: block;
      margin-top: 2px;
    }
  }
  gap: 20px;
}
