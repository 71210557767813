@import "components/ui/__variables";

.ai_input {
    width: 90%;
    border-radius: 5px;
    height: 40px;
    border: none;
}
input:focus{
    outline: none;
}