.login-form {
  button {
    margin-top: 10px;

    margin-bottom: 20px;
  }

  &__inputs {
    display: grid;
    gap: 10px;
  }
  a,
  span {
    color: #f00;
    font-size: 14px;
    font-family: Raleway;
    letter-spacing: 0.28px;
    text-align: right;
    margin-top: 10px;
    text-decoration: none;
    display: block;
  }
  .verify-button {
    padding: 1.8rem 0;
  }
}
