@import "../_variables";
.custom-dropdown {
  button {
    background-color: transparent !important;
    border: 1px solid $white-muted-color;
    color: $black-color;
  }
  button:focus,
  button:hover {
    border: 1px solid $white-muted-color;
    color: $primary-color !important;
  }
  button:focus:not(:focus-visible) {
    border: 1px solid $primary-color;
    color: $primary-color !important;
  }
  .btn-check:focus + .btn,
  .btn:focus {
    border: 1px solid $primary-color;
    box-shadow: none !important;
    color: $primary-color !important;
  }
  
  .dropdown-menu {
    background-color: $white-color;
    line-height: 1.5rem;
    font-size: 14px;
    margin-top: 0.2rem;
    padding: 16px;
    border: 1px solid $border-color;
    border-radius: 4px;
    text-decoration: none !important;
    display: block !important;
    a {
      text-decoration: none !important;
      color: $black-color;
    }
    &.active{
      border: 1px solid $primary-color !important;
    }
  }
}
