@import "components/ui/__variables";

.dashboard-cards {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.card_icon {
  margin: 10px 0;
}
.best_metric {
  padding: 15px 15px;
  width: 320px;
  height: 150px;
  margin: 1rem 0;
  border: 1px solid $card-border-color;
  border-radius: 10px;
  background: $white-color;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.05);
  font-family: Raleway;
  @media screen and (max-width: $large-screen) {
    max-width: 320px;
  }
  h2 {
    margin: 10px 0;
    font-weight: 500;
    color: #3498db;
  }
}
.dashcard_metric {
  padding: 15px 15px;
  width: 250px;
  margin: 1rem 0;
  border: 1px solid $card-border-color;
  background: $white-color;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.05);
  font-family: Raleway;
  @media screen and (min-width: $large-screen) {
    max-width: 250px;
  }
}
.dashcard_content {
  padding: 15px 15px;
  width: 100%;
  // width: 200px;
  margin: 1rem 0;
  border-radius: 10px;

  border: 1px solid $card-border-color;
  background: $white-color;
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.05);
  font-family: Raleway;
  @media screen and (min-width: $large-screen) {
    max-width: 180px;
  }

  &__layer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  b {
    color: $black-color;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.32px;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h6 {
   margin: 0 !important;
  }
  h2 {
    margin: 10px 0;
    font-weight: 500;
    color: #3498db;
  }
}
.metric_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  h6 {
    cursor: pointer !important;
    font-size: 14px;
  }
}
.metric_head .tooltiptext {
  visibility: hidden;
  // width: 90px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  font-size: 10px;

  position: absolute;
  bottom: 0;
  z-index: 1;
}

.metric_head:hover .tooltiptext {
  visibility: visible;
}
