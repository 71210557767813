@import "components/ui/__variables";

.admin-view-kpi-table {
  margin-top: 32px;
  &__header {
    display: flex;
    padding: 10px 10px 0px 10px;
    gap: 10px;
    border-top: 1px solid #e1e2e6;
    border-bottom: 1px solid #e1e2e6;
    background: #fff;
    margin-bottom: 24px;
    span {
      color: rgba(0, 44, 97, 0.5);
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
  &__table {
    box-shadow: 0px 4px 4px 0px rgba(164, 164, 164, 0.25);
  }
}
