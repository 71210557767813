.admin-view-team-kpi {
  section {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__links {
    display: flex;
    gap: 10px;
    span {
      color: #666e80;
      text-align: right;
      font-family: Nunito;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      &.active {
        color: #0e91ef;
      }
    }
  }

  h3 {
    color: #000;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.28px;
  }
  table {
    table-layout: fixed;
    tbody {
      td {
        font-size: 12px;
        text-transform: capitalize;
        // width: 10px;
        span {
          width: 100%;

          word-wrap: break-word;
        }
      }
    //   td:nth-child(4) {
    //     text-transform: lowercase;
    //   }
    }
  }
}
