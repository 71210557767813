@import "../_variables";

.custom-table {
  box-sizing: border-box;

  background-color: $white-color;

  table {
    table-layout: fixed;

    font-family: Raleway;

    width: 100% !important;

    border: none;

    border-collapse: collapse;

    @media screen and (max-width: $large-screen) {
      display: block;

      overflow-x: auto;
      
      white-space: nowrap;
    }
    // white-space: nowrap;
  }

  .custom-dropdown {
    button {
      background-color: transparent !important;
      border: none !important;
      width: 2.5rem !important;
    }
  }
  input {
    margin-right: 13.19px;

    background: $white-color;

    border: 1px solid #c0c8d2 !important;

    box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08),
      0px 1px 1px rgba(0, 0, 0, 0.12);

    border-radius: 4px;
  }

  tbody {
    border-top: none;
    tr {
      border: 0.5px solid $white-muted-color;

      border-left: none;

      border-right: none;

      width: 100% !important;
    }

    td:first-child {
      &.actions {
        display: flex;

        height: fit-content;

        align-items: center;
      }
    }

    td:last-child {
      display: flex;

      // gap: 10px;

      align-items: center;

      width: fit-content;

      background-color: $white-color;

      &.actions {
        margin-left: auto;

        margin-right: auto;

        gap: 10px;
      }

      // position: absolute;

      // @media screen and (max-width: $large-screen) {
      //   position: fixed;

      //   right: 30px;
      // }

      svg {
        cursor: pointer;

        display: flex;

        height: 100%;

        // margin-right: 40px;
      }
    }

    td {
      color: var(--text-icon-primary-black, #1d2433);

      font-size: 14px;

      font-family: Raleway;

      line-height: 20px;

      padding: 10px 8px;

      width: 100%;

      word-wrap: break-word;

      // width: 400px !important;
    }
  }

  thead {
    background-color: $white-color;

    color: $black-color;

    th:first-child {
      &.actions {
        display: flex;

        height: fit-content;

        align-items: center;
      }
    }

    th {
      padding: 10px 8px;

      font-style: normal;

      color: $black-color;

      font-size: 14px;

      font-family: Raleway;

      font-weight: 700;

      line-height: 16px;

      letter-spacing: 0.28px;

      text-transform: capitalize;

      span,
      p,
      div,
      element {
        display: flex;

        align-items: center;
      }
    }
  }

  &__pagination {
    display: flex;

    justify-content: space-between;

    // margin-top: 15px;

    display: flex;

    padding: 8px 21px;

    justify-content: center;

    align-items: center;

    gap: 10px;

    background: #fff;

    svg {
      padding: 15px;
      color: $black-color;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: #e1e2e6;
      }
    }

    .non-active {
      background-color: #e1e2e6;
      stroke: $gray-color;
      cursor: none;
    }

    span {
      display: flex;

      color: #666e80;

      text-align: right;

      font-size: 10px;

      font-weight: 700;

      width: 24px;

      height: 24px;

      padding: 8px;

      justify-content: center;

      align-items: center;

      cursor: pointer;

      border-radius: 4px;

      &:hover {
        background-color: #e1e2e6;
      }

      &.active {
        width: 24px;
        height: 24px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background: #008ee2;
        color: $white-color;
        color: #fff;
        font-size: 10px;
        font-weight: 700;
      }
    }

    div {
      display: flex;

      // gap: 8px;

      align-items: center;
    }
  }

  .low {
    display: flex;
    height: 30px;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: #fbe7e9;
    color: var(--layout-800, #222);
    font-size: 10px;
    width: fit-content !important;
    font-weight: bold;
    // font-family: Poppins;
    text-transform: capitalize;
  }

  .high {
    display: flex;
    height: 30px;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: #d4f8d3;
    color: var(--layout-800, #222);
    font-size: 10px;
    font-family: Poppins;
    width: fit-content !important;
    font-weight: bold;
    text-transform: capitalize;
  }

  .average {
    display: flex;
    height: 30px;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: #ede7fb;
    color: var(--layout-800, #222);
    font-size: 10px;
    font-family: Poppins;
    text-transform: capitalize;
  }

  .burnout {
    display: flex;
    height: 30px;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: #fff0bb;
    color: var(--layout-800, #222);
    font-size: 10px;
    font-family: Poppins;
    text-transform: capitalize;
  }
}
