@import "components/ui/__variables";

.view-po-task {
  &__heading {
    display: flex;
    gap: 13px;
    .icon {
      display: flex;
      width: 43px;
      height: 43px;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background: #e0f1fb;
    }
    h4 {
      color: #000;
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      margin-top: 0;
      margin-bottom: 0;
    }
    p {
      color: #9e9696 !important;
      font-family: Raleway;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 150% !important;
      margin-top: 0;
      margin-bottom: 0;
      b {
        color: #000;
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 400 !important;
        line-height: 150%;
      }
    }

    span {
      width: 70px;
      height: 34px;
      background: #e0f1fb;
      color: #000;
      font-family: Raleway;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      //   text-align: center;
      padding: 9px;
      padding-bottom: 0px;
      //   padding-top: 2.5px;
      //   padding-left: 4.5px;
      //   padding-right:4.5px ;
    }
  }
  h3 {
    color: #000;
    font-family: Raleway;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.36px;
    display: flex;
    align-items: center;
    gap: 4px;
    svg {
      path {
        fill: $primary-color;
      }
      cursor: pointer;
    }
  }

  p {
    color: #000;
    font-family: Raleway;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
  }

  &__breakdown {
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

    &__header {
      display: flex;
      padding: 13px 8px;
      gap: 8px;
      background: #fafcff;
      justify-content: space-between;
      span {
        color: var(--text-icon-primary-black, #1d2433);
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 114.286% */
        letter-spacing: 0.28px;
        text-transform: capitalize;
      }
    }

    &__row {
      display: flex !important;
      height: 30px;
      padding: 10px 8px;
      background: #fafcff;
      justify-content: space-between;
      align-items: center;
      span {
        color: var(--text-icon-primary-black, #1d2433);
        font-feature-settings: "clig" off, "liga" off;
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        display: flex;
        align-items: center;
        gap: 4px;
        svg {
          path {
            fill: $primary-color;
          }
          cursor: pointer;
        }
      }

      .custom-select {
        width: 228px !important;
        display: flex;
        height: 40px !important;
      }

      .status-detail {
        border-radius: 3px;
        border: 1px solid #d4f8d3;
        background: rgba(212, 248, 211, 0.6);
        display: flex;
        height: 30px;
        padding: 4px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }

      .label-detail {
        display: flex;
        height: 30px;
        padding: 4px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 3px;
        border: 1px solid #fff0bb;
        background: rgba(255, 240, 187, 0.6);
      }
    }
  }
}
