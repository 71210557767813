@import "components/ui/__variables";

.admin-view-kpi-breakdown {
  margin-bottom: 20px;
  border-bottom: 1px solid #e5e6eb;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(164, 164, 164, 0.25);
  &__header {
    display: flex;
    padding: 20px 16px 16px 16px;
    border-bottom: 1px solid #f5f6fa;
    background: #edf7fd;
    justify-content: space-between;
    svg {
      path {
        fill: #071939;
      }
      cursor: pointer;
    }
    p {
      color: rgba(7, 25, 57, 0.87);
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &__table {
    padding: 25px 27px;
    .custom-table {
      box-shadow: 0px 4px 4px 0px rgba(164, 164, 164, 0.25);
      // border:1px solid rgba(164, 164, 164, 0.25);
      padding: 4px;
    }
  }
}
