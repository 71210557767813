@import "components/ui/__variables";

.blocker_container {
  margin-top: 1.5rem;
}
.blocker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.5px solid black;
  padding: 0 10px !important;
  height: 35px;
  border-radius: 3px;
  margin-bottom: 1rem;
  p {
    font-weight: 500;
    font-size: 14px;
  }
}
