@import "components/ui/__variables";

.admin-create-kpi-form {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // gap: 19px;
    // padding: 31px 26px;
  }

  &__error {
    p {
      color: $cancel-color;
      font-size: 12px;
      text-align: center;
      margin-bottom: 0 !important;
    }
  }

  &__select-indicator {
    margin-top: 56px;
    gap: 10px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #e1e2e6;
    padding: 0px 8px;
    span {
      color: #666e80;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-bottom: 8px;
      cursor: pointer;
      &.active {
        color: #008ee2;
      }
    }
  }

  &__form {
    margin-top: 25px;
    &-container {
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
      margin-bottom: 18px;
    }
    &-manual-btn {
      display: flex;
      justify-content: space-between;
      button.delete {
        border: none !important;
        background: #e9e9f0 !important;
        border: none !important;
        color: #071939 !important;
        display: flex !important;
        align-items: center !important;
        &:hover {
          color: $cancel-color !important;
          path {
            fill: $cancel-color !important;
          }
        }
        svg {
          margin-top: 6px !important;
          width: 8px;
          path {
            fill: #071939 !important;
          }
        }
      }
    }
    &-header {
      display: flex;
      display: flex;
      // height: 40px;
      padding: 13px 8px;
      align-items: center;
      gap: 8px;
      justify-content: space-between;
      background: #fafcff;
      span {
        color: #1d2433;
        // font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0.28px;
        text-transform: capitalize;
      }
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      bottom: 24px;
      position: absolute;
      right: 24px;
      left: 24px;
      button {
        height: 40px !important;
        padding: 6px 12px !important;
        align-items: center;
        border-radius: 8px !important;
        font-family: DM Sans;
        font-size: 10px !important;
        font-style: normal;
        font-weight: 500 !important;
        &.cancel {
          background: #e9e9f0 !important;
          border: none !important;
          color: #071939 !important;
        }
      }
    }
  }

  &__button {
    width: 102px !important;
    height: 45px !important;
    padding: 6px 12px !important;
    margin-left: auto;
    color: #071939;
    font-family: DM Sans;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;
  }
}
