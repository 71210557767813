@import "components/ui/__variables";

.create-team-form {
  margin-top: 1.5rem;
}
.form_items {
  display: block;
  width: 100%;
  .item {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.select_employee {
  position: relative;
  margin-top: 1rem;
  .add_btn {
    position: absolute;
    top: -15%;
    right: 0;
    width: 120px;
    height: 30px;
    padding: 2px;
    border-radius: 8px;
    color: white;
    font-size: 12px;
    font-family: DM Sans;
    font-weight: 500;
    &:hover {
      svg {
        fill: $primary-color;
        path {
          fill: $primary-color;
        }
      }
    }
  }
}



.admin-team_btn {
  display: flex;
  justify-content: right;
  margin-top: 1rem;

  button {
    height: 40px !important;
    padding: 6px 12px !important;
    align-items: center;
    border-radius: 8px !important;
    font-family: DM Sans;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 500 !important;
    &.cancel {
      background: #e9e9f0 !important;
      border: none !important;
      color: #071939 !important;
      font-size: 14px !important;
    }
    &.submit {
      margin-left: 1rem;
      font-size: 14px !important;
    }
  }

  &__button {
    width: 102px !important;
    height: 45px !important;
    padding: 6px 12px !important;
    margin-left: auto;
    color: #071939;
    font-family: DM Sans;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;
  }
}
