@import "components/ui/__variables";

.admin-view-kpi-team-activity {
  max-height: 420px;
  padding-bottom: 0px;
  border-bottom: 1px solid #e5e6eb;
  background: #fff;
  margin-top: 12px;
  box-shadow: 0px 4px 4px 0px rgba(164, 164, 164, 0.25);
  &__header {
    border-bottom: 1px solid #f5f6fa;
    background: #edf7fd;
    display: flex;
    padding: 20px 16px 16px 16px;
    align-items: center;
    justify-content: space-between;
    // width: 100%;
    svg {
      path {
        fill: #071939;
      }
      cursor: pointer;
    }
    h3 {
      color: rgba(7, 25, 57, 0.87);
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &__chart {
    height: fit-content;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 12px;
    overflow: scroll;
  }
}
