.privacy-container {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column;
  // box-shadow: 0px 4px 4px 0px #00000040;
  margin-top: 2rem;
}

.privacy-header {
  background-color: #008ee2;
  width: 100%;
  color: #ffffff;
  padding: 2rem 0;
  border-radius: 5px;
  text-align: center;
  position: relative;
  h4 {
    font-size: 24px;
    font-weight: 500;
    margin: 0 !important;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    padding: 1rem 0 !important;
    line-height: 30px;
    padding: 0 3rem;
    margin: 0 3rem;
  }
  a {
    text-decoration: none;
  }
}

.privacy-body {
  width: 100%;
  padding: 3rem 0;
}
.privacy-item {
  margin: 0 0 1.5rem 0;
  h4, p {
    margin: 0.5rem 2rem !important;
    font-size: 14px;
  }
  li {
    font-size: 14px;
    margin: 1rem 0;
  }
}
