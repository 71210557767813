@import "components/ui/__variables";

.filter-modal {
  .react-responsive-modal-modal {
    padding: 0 !important;
  }
  &__header {
    display: flex;
    // height: 54px;
    padding: 20px 16px 16px 16px;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    border-bottom: 1px solid #f5f6fa;
    background: #edf7fd;

    span {
      color: rgba(7, 25, 57, 0.87);
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  &__content {
    padding: 20px 16px 16px 16px;
  }

  &__buttons {
    display: flex;
    padding: 20px 16px 16px 16px;
    justify-content: space-between;
    .cancel {
      height: 40px;
      padding: 6px 12px;
      align-items: center;
      gap: 10px;
      border-radius: 3px;
      background: #e9e9f0;
      border: none;
      color: #071939;
      font-family: DM Sans;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .apply {
      display: flex;
      height: 40px;
      padding: 6px 12px;
      align-items: center;
      gap: 10px;
      color: var(--White, #fff);
      font-family: DM Sans;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
