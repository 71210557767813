.signup-form {
  &__inner {
    display: grid;
    gap: 16px;
    .onboarding-button {
      margin-top: 1.5rem;
      padding: 1.8rem 0;
    }
  }
  
  .signup-button {
    margin-top: 1.5rem;
    padding: 2rem 0;
  }
}
