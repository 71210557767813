@import "components/ui/__variables";

.email_authentication {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    color: #008EE2;
    font-size: 16px;
  }
  .input {
    padding: 1.2rem 0;
    padding-left: 1rem;
    background-color: transparent;
    border: 1px solid #008EE2;
    width: 500px;
    border-radius: 5px;
  }
  .scan_code {
    text-align: center;
  }
}
