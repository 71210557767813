@import "components/ui/__variables";

.layout-sidebar__item {
  padding: 10px 12px;
  align-items: center;
  display: flex;
  cursor: pointer;
  margin-bottom: 10px;
  text-decoration: none;
  gap: 10px;
  span {
    color: $white-color;
    font-size: 16px;
    font-family: Inter;
    font-weight: 500;
    line-height: 24px;
    margin-right: auto;
  }

  svg {
    margin-bottom: 0;
  }

  &__left-icon {
    padding: 10px;
    border-radius: 10px;
    background: #71839b;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    path {
      fill: $white-color !important;
    }
    svg {
      width: 18px;
      height: 14px;
    }
  }

  &:hover {
    border-radius: 10px;
    border-left: 2px solid $primary-color;
    background: rgba(255, 255, 255, 0.2);
  }

  &.active {
    border-radius: 10px;
    border-left: 2px solid $primary-color;
    background: rgba(255, 255, 255, 0.2);
    div {
      &.active__svg {
        padding: 10px;
        gap: 10px;
        border-radius: 10px;
        background: $primary-muted-color;
        opacity: 1;
        path {
          fill: $white-color !important;
        }
      }
    }
  }
}
