@import "components/ui/__variables";

.pages-tested {
  background: $white-color;
  padding: 24px;
  display: flex;
  height: 318px;
  flex-direction: column;
  // justify-content: space-between;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      color: #000;
      font-family: Raleway;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.24px;
      margin-bottom: 0;
      margin-top: 0;
    }
    span {
      color: #008ee2;
      font-family: Raleway;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.2px;
    }
  }

  &__chart-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__chart {
      width: 120px;
      height: 200px;
      // height: 98.624px;
      flex-shrink: 0;
    }
    h5 {
      color: #000;
      font-family: Raleway;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin-top: 0;
    }

    .recharts-legend-item-text {
      color: $black-color !important;
      font-family: Raleway;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
