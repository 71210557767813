@import "components/ui/__variables";

@mixin tooltipStyles {
  width: fit-content;
  background: #7dcfb6;
  padding: 12px;
  border-radius: 8px;
  position: absolute;
  text-align: center;
}

.layout-header {
  width: 100%;

  height: 96px;

  background: $primary-muted-color;

  // background: rgba(0, 143, 227, 0.15);
  background-color: #008ee21a;

  display: flex;

  justify-content: space-between;

  align-items: center;

  position: fixed;

  .utils_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media screen and (max-width: $large-screen) {
    height: 71px;
  }

  .picture {
    width: 50px;
    height: 50px;
    margin-top: 16px;
    border-radius: 30px;
  }

  &__mobile-logo {
    svg {
      width: 154px;
      height: 36px;
    }

    @media screen and (min-width: $large-screen) {
      display: none;
    }
  }

  section:first-child {
    margin-left: 46px;

    i {
      @media screen and (min-width: $large-screen) {
        display: none !important;
      }
    }

    @media screen and (max-width: $large-screen) {
      margin-left: 30px;
      svg {
        display: none;
      }

      width: 39px;
      height: 39px;
      background-color: rgba($primary-muted-color, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        color: #008ee2;
        font-size: 22px;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        cursor: pointer;
      }
    }
  }

  &__user-box {
    display: flex;

    width: fit-content;

    padding: 10px;

    align-items: center;

    gap: 16px;

    height: 64px;

    border-radius: 8px;

    background: rgba(255, 255, 255, 0.3);

    margin-right: 46px;

    justify-content: space-between;

    @media screen and (max-width: $large-screen) {
      width: fit-content;
      margin-right: 30px;
      gap: 0;
      background: transparent;
      // display: none;
    }

    &__userguide {
      background: #002c61;
      margin: 0 0.3rem;
      padding: 10px;
      border-radius: 8px;
      cursor: pointer;
      .item {
        display: flex;
        align-items: center;
      }
    }

    &__clockin {
      background: #008ee2;
      margin: 0 0.3rem;
      padding: 10px;
      border-radius: 8px;
      cursor: pointer;
      .item {
        display: flex;
        align-items: center;
        .clock_icon {
          width: 25px;
          height: 25px;
        }
      }
    }

    &__clockout {
      background: #f20505;
      margin: 0 0.3rem;
      padding: 10px;
      border-radius: 8px;
      cursor: pointer;
      .item {
        display: flex;
        align-items: center;
        .clock_icon {
          width: 25px;
          height: 25px;
        }
      }
    }

    &__avatar {
      border-radius: 30px;

      cursor: pointer;

      svg {
        width: 44px;

        height: 44px;
      }
    }

    h3 {
      color: $black-color;

      font-size: 16px;

      font-family: Raleway;

      font-weight: 700;

      letter-spacing: 0.32px;

      margin-bottom: 0;

      margin-top: 0;

      @media screen and (max-width: $large-screen) {
        display: none;
      }
    }

    p {
      margin-top: 0;

      color: $black-color;

      font-size: 12px;

      font-family: Raleway;

      font-weight: 500;

      margin-bottom: 0;

      @media screen and (max-width: $large-screen) {
        display: none;
      }
    }

    &__arrow-down {
      margin-left: 10px;

      @media screen and (min-width: $large-screen) {
        display: none;
      }

      svg {
        path {
          fill: #008ee2;
        }
        cursor: pointer;
      }
    }

    &__log-out {
      width: 38px;

      height: 38px;

      border-radius: 4px;

      border-radius: 4px;

      background: rgba(0, 142, 226, 0.1);

      display: flex;

      align-items: center;

      justify-content: center;

      cursor: pointer;

      @media screen and (max-width: $large-screen) {
        display: none;
      }

      svg {
        width: 18px;

        height: 18px;
      }
    }
  }
}

.box-employees,
.box-add-employees,
.box-filter-employees,
.box-userguide {
  @include tooltipStyles;
}

.box-userguide {
  &::before {
    content: "" !important;
    position: absolute !important;
    border-style: solid !important;
    border-width: 8px !important;
    border-color: transparent transparent #7dcfb6 transparent !important;
    top: -16px !important;
    left: 60% !important;
    transform: translateX(-50%) !important;
  }

  @media screen and (min-width: 1248px) {
    right: 13rem !important;
  }
}

.box-employees,
.box-user-settings,
.box-project_overview {
  &::before {
    content: "" !important;
    position: absolute !important;
    border-style: solid !important;
    border-width: 8px !important;
    border-color: transparent transparent #7dcfb6 transparent !important;
    top: -16px !important;
    left: 60% !important;
    transform: translateX(-50%) !important;
  }

  @media screen and (min-width: 1024px) {
    top: 17rem !important;
  }

  @media screen and (min-width: 1248px) {
    top: 15rem !important;
    left: 16rem !important;
  }
}

.box-password-settings {
  &::before {
    content: "" !important;
    position: absolute !important;
    border-style: solid !important;
    border-width: 8px !important;
    border-color: transparent transparent #7dcfb6 transparent !important;
    top: -16px !important;
    left: 60% !important;
    transform: translateX(-50%) !important;
  }

  @media screen and (min-width: 1024px) {
    top: 17rem !important;
  }

  @media screen and (min-width: 1248px) {
    top: 15rem !important;
    left: 40rem !important;
  }
}

.box-integrations-settings {
  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 40%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #7dcfb6 transparent transparent transparent;
  }

  @media screen and (min-width: 1024px) {
    top: 17rem !important;
  }

  @media screen and (min-width: 1248px) {
    top: 8.5rem !important;
    left: 50rem !important;
  }
}

.box-teams {
  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 60%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #7dcfb6 transparent transparent transparent;
  }

  @media screen and (min-width: 800px) {
    top: 9rem !important;
    left: 6rem !important;
  }

  // @media screen and (min-width: 1024px) {
  //   top: 17rem !important;
  // }

  @media screen and (min-width: 1248px) {
    top: 8rem !important;
    left: 23rem !important;
  }
}

.box-add-employees {
  left: 0.5rem;
  top: 7rem;

  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #7dcfb6 transparent transparent transparent;
  }

  @media screen and (min-width: 800px) {
    .box-add-employees {
      left: 0 !important;
      right: 3rem !important;
      top: 5rem;

      &::before {
        content: "";
        position: absolute;
        border-style: none;
        border-width: 8px;
        border-color: transparent transparent #7dcfb6 transparent;
        top: -16px;
        left: 60%;
        transform: translateX(-50%);
      }
    }
  }
}

.box-filter-employees {
  &::before {
    content: "" !important;
    position: absolute !important;
    border-style: solid !important;
    border-width: 8px;
    border-color: transparent transparent #7dcfb6 transparent !important;
    top: -16px !important;
    left: 60% !important;
    transform: translateX(-50%) !important;
  }

  @media screen and (min-width: 800px) {
    right: 12rem !important;
    top: 12rem !important;
  }

  @media screen and (min-width: 1248px) {
    top: 10rem !important;
  }
}

.box-company-settings,
.box-kpis {
  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 40%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #7dcfb6 transparent transparent transparent;
  }

  @media screen and (min-width: 800px) {
    top: 9rem !important;
    left: 8rem !important;
  }

  @media screen and (min-width: 1248px) {
    left: 25rem !important;
    top: 8rem !important;
  }
}

.tooltip-modal-btn {
  padding: 16px;
  background: #ffffff;
  color: #002c61;
  font-size: 20px;
  font-weight: 500;
  border-radius: 8px;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  border: none;
  cursor: pointer;
}

.add_card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
  h4 {
    margin-bottom: 0;
  }
  p {
    margin-bottom: -0.3rem;
    font-size: 15px;
  }
}
.paystack_btn {
  border: none !important;
  height: 30px;
  background: none !important;
  margin-bottom: 2rem;
}



.bounce {
  animation: bounce 1s ease infinite;
}

@keyframes bounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-15%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}


.animate-clock {
  animation: bounce 1s ease infinite;
}

@keyframes animate-clock {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-15%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}