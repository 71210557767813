.contribution-graph {
  width: 100%;
  text {
    font-family: DM Sans !important;
  }
  svg {
    font-family: DM Sans !important;
    text {
      font-family: DM Sans !important;
    }
  }
}
