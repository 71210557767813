@import "components/ui/__variables";

.page-header {
  margin-bottom: 23px;

  &__top {
    display: flex;

    align-items: center;

    justify-content: space-between;

    @media screen and (max-width: $medium-screen) {
      display: block;
    }

    .search-input {
      @media screen and (max-width: $medium-screen) {
        width: 100%;
        margin-top: 10px;
      }
    }
    h3 {
      color: #000;

      font-size: 32px;

      // font-family: Raleway;
      margin-top: 0;

      margin-bottom: 0;

      font-weight: 700;

      letter-spacing: 0.64px;

      @media screen and (max-width: $large-screen) {
        color: #000;

        font-family: Raleway;

        font-size: 18px;

        font-style: normal;

        font-weight: 700;

        line-height: normal;

        letter-spacing: 0.36px;
      }
    }

    .page-title {
      cursor: pointer;
      position: relative;
    }

    &__actions {
      display: flex;

      gap: 10px;

      align-items: center;

      height: fit-content;

      @media screen and (max-width: $large-screen) {
        display: block;
      }

      &__buttons {
        display: flex;

        justify-content: space-between;

        align-items: center;

        gap: 10px;

        @media screen and (max-width: $large-screen) {
          margin-top: 10px;
        }

        @media screen and (max-width: $medium-screen) {
          margin-top: 10px;

          // justify-content: right;

          flex-direction: row-reverse;
        }
      }

      .filter__download {
        width: fit-content;

        height: 45px;

        padding: 6px 12px;

        border-radius: 8px;

        background: #e9e9f0 !important;

        border: none;

        color: #071939;

        font-size: 12px;

        font-family: DM Sans;

        font-weight: 500;

        display: flex;

        align-items: center !important;

        svg {
          margin-top: 7px;
        }

        &:hover {
          svg {
            fill: $primary-color;
            path {
              fill: $primary-color;
            }
          }
        }
      }
      .add__btn {
        width: fit-content;
        height: 45px;
        padding: 6px 12px;
        border-radius: 8px;
        color: var(--white, #fff);
        font-size: 12px;
        font-family: DM Sans;
        font-weight: 500;
        display: flex;
        align-items: center !important;
        &:hover {
          svg {
            fill: $primary-color;
            path {
              fill: $primary-color;
            }
          }
        }
      }
    }
  }

  &__bottom {
    display: flex;

    padding: 10px 10px 0px 10px;

    padding-left: 0;

    gap: 10px;

    border: 1px solid #e1e2e6;

    background: #fff;

    margin-top: 25px;

    @media screen and (max-width: $medium-screen) {
      overflow-x: scroll;
    }

    a {
      color: #666e80;
      font-size: 14px;
      padding: 6px 20px;
      font-family: Raleway;
      font-weight: 500;
      text-decoration: none;
      // margin-left: 10px;
      @media screen and (max-width: $medium-screen) {
        font-size: 12px;
      }
      &.active {
        border-bottom: 1px solid #008ee2;
        color: #008ee2;
        background: #fff;
        box-shadow: 0px 2px 16px 0px rgba(11, 131, 217, 0.07);
      }
    }
  }
}
