.twofa-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  button {
    margin-top: 10px;

    margin-bottom: 20px;
  }

  &__inputs {
    display: grid;
    gap: 10px;
  }
  .twofa-form-container {
    width: 100% !important;
  }
  .verify-button {
    padding: 1.8rem 0;
  }
}
