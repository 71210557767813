@import "../_variables";

.default-modal {
  .react-responsive-modal-modal {
    width: 400px !important;
    height: fit-content;
    padding: 1.5rem;

    @media screen and (max-width: $large-screen) {
      width: 30rem !important;
    }
  
    @media screen and (max-width: $medium-screen) {
      width: 400px !important;
    }
  
    @media screen and (max-width: $small-screen) {
      width: 75% !important;
    }
  }


  .react-responsive-modal-closeButton {
    svg {
      margin-left: auto;
      display: block;
      width: fit-content;
      fill: $black-color;
      cursor: pointer;
      font-size: 1.5rem;
    }
  }
  .icon {
    margin-left: auto;
    display: block;
    width: fit-content;
    color: $black-color;
    cursor: pointer;
    font-size: 1.5rem;
  }
  .close--button {
    width: 100%;
    background-color: $primary-color;
    height: 3rem;
    font-weight: 600;
    margin-top: 2rem;
  }
  h3 {
    margin: 0;
  }
}
