.admin-user-table {
  table {
    table-layout: fixed;
    tbody {
      td {
        font-size: 12px;
        text-transform: capitalize;
        // width: 10px;
        span {
          width: 100%;

          word-wrap: break-word;
        }
      }
      td:nth-child(4){
        text-transform: lowercase;
      }
    }
  }
}
