@import "components/ui/__variables";

.dashboard {
  &__section {
    display: grid;
    gap: 14px;
    grid-template-columns: 3.1fr 1.3fr;
    margin-top: 15px;
    @media screen and (max-width: $large-screen) {
      grid-template-columns: 1fr;
      width: 100%;
    }
    @media screen and (max-width: $small-screen) {
      display: block
    }

  }

  &__chart {
    background-color: $white-color;
      width: 50%;
    height: 318px;
    padding: 24px;
    margin-top: 2rem;
    margin: 1rem 1rem 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: $small-screen) {
      width: 85%;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
      h3 {
        color: #000;
        font-family: Raleway;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }
    }

    &__chart {
      height: 100%;
      .recharts-legend-item-text {
        color: $black-color !important;
        font-family: Raleway;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      tspan {
        color: #333 !important;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  &__chart1 {
    background-color: $white-color;
      width: 50%;
    height: 318px;
    padding: 24px;
    margin-top: 2rem;
    margin: 1rem 0 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: $small-screen) {
      width: 85%;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
      h3 {
        color: #000;
        font-family: Raleway;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
      }
    }

    &__chart {
      height: 100%;
      .recharts-legend-item-text {
        color: $black-color !important;
        font-family: Raleway;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      tspan {
        color: #333 !important;
        text-align: center;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
