// @import "components/ui/__variables";

// .project {
//   display: grid;
//   // grid-template-columns: 0.25fr 0.75fr;

//   grid-template-columns: 0.25fr 0.75fr;
//   gap: 12px;
//   position: relative;
//   width: 100%;

//   &__details {
//     position: absolute;
//     width: 53.5vw;
//     // width: 55vw;
//     right: 28px;

//     // top:200px;
//     overflow: scroll;
//     position: fixed;
// //     @media screen and (max-width: 1400px) {
// // display: none;
// //     }
//     @media screen and (max-width: $large-screen) {
//       display: none;
//       width: calc(100vw - 50px);
//       right: 0px;
//       left: 0px;
//       margin-right: auto;
//       margin-left: auto;
//     }
//   }
// }

@import "components/ui/__variables";

.project {
  display: grid;
  // display: flex;
  grid-template-columns: 0.3fr 0.7fr;

  gap: 12px;

  right: 30px;

  left: 300px;

  @media screen and (max-width: $large-screen) {
    left: 30px;
    grid-template-columns: 0.5fr minmax(0, 1fr);
  }

  @media screen and (max-width: $medium-screen) {
    display: block;

    left: 30px;
  }

  &__details {
    // overflow:hidden;
    // max-width: auto;
    // width: 55vw;
    // right: 30px;

    // left:50%;
    // right: 0px;

    // width: calc(43.5vw + 20%);

    // top:200px;
    // overflow: scroll;
    //     @media screen and (max-width: 1400px) {
    // display: none;
    //     }
    @media screen and (max-width: $large-screen) {
      // width: calc(100vw - 50px);
      // right: 0px;
      // left: 0px;
      // margin-right: auto;
      // margin-left: auto;
    }
  }
}
