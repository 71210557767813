@import "components/ui/Main";

.auth-header {
  margin-bottom: 32px;

  @media screen and (max-width: $medium-screen) {
    margin-bottom: 32px;
  }

  h1 {
    font-style: normal;

    font-weight: 700;

    font-size: 32px;

    line-height: 38px;

    color: $black-color;

    margin-top: 0;

    letter-spacing: 0.64px;

    margin-bottom: 0;
    text-align: center;

    @media screen and (max-width: $medium-screen) {
      font-size: 24px;

      line-height: 32px;

      text-align: center;
    }
  }

  h3 {
    color: #666e80;;

    font-size: 14px;

    font-family: Raleway;

    font-weight: 400;
    text-align: center;

    @media screen and (max-width: $medium-screen) {
      text-align: center;
    }
  }
}
