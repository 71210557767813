@import "../../_variables";

.side-modal {
  width: 35rem !important;
  // padding: 2rem;
  overflow: scroll;
  padding: 0 !important;
  padding-top: 0 !important;
  z-index: 1000000000 !important;

  @media screen and (max-width: $large-screen) {
    width: 30rem !important;
  }

  @media screen and (max-width: $medium-screen) {
    width: 400px !important;
  }

  @media screen and (max-width: $small-screen) {
    width: calc(100% - 20px) !important;
  }

  &__heading {
    margin-top: 0 !important;
    // height: 54px;
    // padding: 20px 16px 16px 16px;
    border: 0px 0px 1px 0px;

    border-bottom: 1px solid #f5f6fa;

    background: #edf7fd;

    padding-left: 2rem !important;

    padding-right: 2rem !important;

    padding-bottom: 0rem !important;

    @media screen and (max-width: $large-screen) {
      padding-left: 12px !important;

      padding-right: 12px !important;
    }
    &.custom {
      margin-left: -2rem;
      margin-right: -2rem;
      margin-top: -2rem !important;
      margin-bottom: 2rem;

      @media screen and (max-width: $large-screen) {
        margin-left: -1rem;
        margin-right: -1rem;
        margin-top: -1rem !important;
        display: block;
        height: fit-content;
        padding-bottom: 12px;
        padding-top: 20px;
        justify-content: center;
        .side-modal__heading__content {
          display: flex;
          margin-top: 4px;
        }
      }
    }

    // display: flex;

    // min-height: 54px;

    height: fit-content;

    padding: 20px 16px 16px 16px;

    @media screen and (max-width: $large-screen) {
      padding-left: 12px;
      padding-bottom: 12px !important;
      padding-right: 12px;
      padding-top: 0px;
      height: 71px;
      display: flex;
      align-items: center;
    }

    // align-items: flex-start;

    // flex-shrink: 0;

    // align-self: stretch;

    .back__button {
      background: #e9e9f0;
      display: flex;
      width: 80px;
      height: 45px;
      border: none;
      padding: 6px 12px;
      color: $black-color !important;
      svg {
        path {
          fill: $black-color !important;
        }
      }
    }

    button {
      width: fit-content !important;
      height: 45px !important;
      color: var(--White, #fff) !important;
      font-family: DM Sans;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 500 !important;
      line-height: normal;
      display: flex !important;
      align-items: center !important;
      &:hover {
        color: $primary-color !important;
      }
    }

    &__content {
      display: flex;

      justify-content: space-between;

      align-items: center;

      width: 100%;

      svg {
        cursor: pointer;
        width: 20px;
        height: 20px;
      }
    }

    h3 {
      color: rgba(7, 25, 57, 0.87);

      font-family: DM Sans;

      color: #000;
      font-family: Raleway;
      font-size: 28px !important;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.64px;
      margin: 1rem 0;
      padding: 0;

      font-style: normal;

      font-weight: 500;

      line-height: normal;

      font-family: DM Sans;

      font-size: 16px;

      font-weight: 700;

      letter-spacing: 0.64px;

      line-height: 21px;

      text-align: left;

      @media screen and (max-width: $large-screen) {
        margin-bottom: 0;
        margin-top: 0;
        font-size: 24px !important;
      }
    }

    p {
      color: $white-muted-color;
      font-weight: 500;
    }
  }

  &__content {
    padding: 2rem;
    @media screen and (max-width: $large-screen) {
      padding: 12px;
    }
  }

  &__alert {
    min-height: 4rem;
    border: 0.5px solid $primary-color;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    color: $gray-color;
  }

  &__alert-error {
    border-color: $cancel-color;
    color: $cancel-color;
  }
}

.back_div {
  display: flex;
  align-items: center;
}

.back_div h3 {
  margin-left: 0.5rem;
  text-transform: capitalize;
}
