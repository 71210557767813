@import "components/ui/__variables";

.repository-metrics {
  background-color: $white-color;
    width: 100%;
  height: 318px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      color: #000;
      font-family: Raleway;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }
  }

  &__chart {
    height: 100%;
    .recharts-legend-item-text {
      color: $black-color !important;
      font-family: Raleway;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    tspan {
      color: #333 !important;
      text-align: center;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    //   .recharts-legend-wrapper{
    //     bottom: 30px !important;
    //   }
    //   .recharts-default-legend{
    //     bottom: 1rem !important;
    //     padding: 0rem 0 !important;
    //   }
  }
}
