@import "../_variables";

.search-input {
  width: fit-content;

  display: flex;

  border: 0.5px solid $white-muted-color;

  align-items: center;

  border-radius: $border-radius;

  height: 45px !important;

  width: 224px;

  // padding: 13px 16px;

  border-radius: 8px;

  background: #fff;

  input[type="search"] {
    border: none;

    border-radius: 8px;

    outline: none;

    width: 100%;

    color: #7c7c7a;

    font-size: 16px;

    font-family: Open Sans;

    font-weight: 600;

    height: 100%;

    line-height: 140%;

    // padding: 13px 16px;
  }

  svg {
    // margin-top: -3px;
    height: fit-content;
    margin-left: 10px;
    margin-right: 10px;
  }

  input[type="search"]::placeholder {
    color: $white-muted-color;
  }

  input[type="search"]:focus {
    outline: none;

    border-color: $white-muted-color;
  }

  .input-shift {
    margin-left: 8px;
  }
}

.search-input:hover {
  border-color: $primary-color;

  transition: 0.5s;
}
