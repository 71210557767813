@import "components/ui/__variables";

.title {
  text-align: center;
  font-weight: bolder;
  font-size: 30px;
  margin-top: -1rem;
}

.card_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 1rem;
}

.card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1.5rem;
  margin: 0 2rem;
  width: 270px;
  height: 420px;
  border: 0.5px solid rgb(217, 216, 216);
  position: relative;
  margin-bottom: 3rem;
  .recommended {
    padding: 5px 10px;
    background-color: #008fe3;
    position: absolute;
    top: -30px;
    left: 30%;
    color: #ffffff;
    border-radius: 30px;
  }
  h2 {
    margin-bottom: 1rem;
    margin-top: 0;
  }
  .name {
    margin-bottom: 1rem;
    margin-top: 0;
    font-size: 40px;
  }
  h1 {
    margin-bottom: 0;
    margin-top: 0;
    position: relative;
  }
  .per_person {
    position: absolute;
    bottom: 5px;
    font-size: 12px;
  }
  strong {
    font-size: 18px;
    position: absolute;
    top: 5px;
    left: 38.5%;
  }
  p {
    font-size: 14px;
    font-weight: medium;
  }
  span {
    font-size: 10px;
    font-weight: 400;
    padding-left: -4rem;
  }
  .feature {
    display: flex;
    align-items: center;
    margin-bottom: -0.9rem;
  }
}

.paystack_btn {
  border: none !important;
  height: 30px;
  background: none !important;
  margin: 2rem 0;
}

.border {
  border: 1px solid #008fe3;
}

.border_ {
  border: 1px solid #f79256;
}
