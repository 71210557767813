@import "components/ui/__variables";

.tasks-managed {
  background: $white-color;
  padding: 24px;
  display: flex;
  height: 318px;
  flex-direction: column;
  //   justify-content: space-between;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      color: #000;
      font-family: Raleway;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.24px;
      margin-bottom: 0;
      margin-top: 0;
    }
    span {
      color: #008ee2;
      font-family: Raleway;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.2px;
      cursor: pointer;
    }
  }
  &__list {
    overflow-y: scroll;
  }
  p{
    color: #008ee2;
    font-family: Raleway;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.2px;
    cursor: pointer;
    margin-bottom: -12px;
  }
  &__category {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.1px solid $border-color;
    padding-bottom: 12px;
    padding-top: 12px;
    &__name-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      span.picture {
        border-radius: 6px;
        background: #d7e4ff;
        display: flex;
        width: 32px;
        height: 32px;
        align-items: center;
        justify-content: center;
        gap: 8px;
        color: #05205e;
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 450;
        line-height: 20px;
        letter-spacing: 0.2px;
      }
      h5 {
        color: #272e35;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.1px;
        margin-top: 0;
        margin-bottom: 0;
      }
      span.email {
        color: #7e8b99;
        font-family: Raleway;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    span.count {
      width: 39px;
      height: 39px;
      flex-shrink: 0;
      border-radius: 5px;
      background: rgba(0, 142, 226, 0.07000000029802322);
      color: #000;
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
