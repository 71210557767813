@import "../_variables";

.default-modal {
  background: transparent;

//   .modal_content {
//     position: absolute;
//     top: 1rem;
//     right: 0;
//     width: fit-content;
//   }

  .modal_blur {
    backdrop-filter: blur(10px);
    position: absolute;
    top: 1rem;
    right: 0;
    width: 100%;
    height: 100%;
    display: block;
  }

  .polygon {
    position: absolute;
    top: 14rem;
    left: 22rem;
    border: none;
  }

  // .react-responsive-modal-modal {
  //   width: 600px !important;
  //   height: fit-content;
  //   padding: 1.5rem;

  //   @media screen and (max-width: $large-screen) {
  //     width: 30rem !important;
  //   }

  //   @media screen and (max-width: $medium-screen) {
  //     width: 400px !important;
  //   }

  //   @media screen and (max-width: $small-screen) {
  //     width: 75% !important;
  //   }
  // }

  .react-responsive-modal-closeButton {
    svg {
      margin-left: auto;
      display: block;
      width: fit-content;
      fill: $black-color;
      cursor: pointer;
      font-size: 1.5rem;
    }
  }
  .icon {
    margin-left: auto;
    display: block;
    width: fit-content;
    color: $black-color;
    cursor: pointer;
    font-size: 1.5rem;
  }
  .close--button {
    width: 100%;
    background-color: $primary-color;
    height: 3rem;
    font-weight: 600;
    margin-top: 2rem;
  }
}
.task_container {
  margin-bottom: 1rem;
}

select{
  margin-bottom: 1rem;
  outline: none;
  height: $input-height;
  margin-top: 4px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: $border-radius;
  display: block;
  line-height: 25px;
  // -webkit-appearance: none;
  border: 1px solid $white-muted-color;
  background: $white-color;
  transition: border 0.3s ease;
  color: $black-color;
  width: 100%;
  &:focus {
    outline: none;
    border-color: $primary-color;
  }
  &-invert {
    border: 1px solid $primary-color;
  }
  &-invert:focus {
    outline: none;
    border-color: $white-muted-color;
  }
}
