@import "components/ui/__variables";

.po-details {
  padding: 15px;
  background-color: $white-color;
  border: 1px solid var(--colors-gray-200, #ececee);
  box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.05);
  margin-top: 16px;
  h3 {
    color: rgba(7, 25, 57, 0.87);
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__header {
    display: flex;
    border-bottom: 1px solid #f5f6fa;
    background: #edf7fd;
    display: flex;
    // height: 54px !important;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;

    .search-input {
      height: 38px !important;
      display: flex;
      width: 238px !important;

      color: #7e848e !important;
      font-family: DM Sans;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    button {
      display: flex;
      height: 40px !important;
      padding: 6px 12px !important;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      color: var(--white, #fff);
      font-family: DM Sans;
      font-size: 10px !important;
      font-style: normal;
      font-weight: 500 !important;
      line-height: normal;
    }
    button.delete {
      background: #e9e9f0;
      border: none;
    }
  }

  &__actions {
    display: flex;
    gap: 12px;
  }
  &__table {
    width: 100%;
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    gap: 22px;

    @media screen and (max-width: $medium-screen) {
      display: block;
    }

    section {
      display: flex;
      flex-direction: column;
      //   gap: 10px;
      width: 100%;
      @media screen and (max-width: $medium-screen) {
        display: block;
      }
    }
    &__column {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e1e2e6;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 17px;
      padding-top: 17px;
      &.no-border {
        border: none !important;
        @media screen and (max-width: $medium-screen) {
          .p-x-1 {
            padding-left: 0 !important;
          }
        }
      }
      span.lead {
        color: var(--colors-gray-400, #57595b);
        text-align: center;
        font-family: Raleway;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-left: 10px;
        @media screen and (max-width: $medium-screen) {
          padding-left: 0px;
        }
      }
      span.name {
        border-radius: 5px;
        background: rgba(0, 142, 226, 0.07);
        display: flex;
        padding: 7px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: #000;
        font-family: Raleway;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-right: 32px;
        @media screen and (max-width: $medium-screen) {
          margin-right: 0px;
        }
      }

      span.progress {
        border-radius: 6px;
        background: #fff0bb;
        display: flex;
        padding: 7px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: var(--layout-800, #222);
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media screen and (max-width: $medium-screen) {
          // padding-left: 0px;
        }
      }
    }

  }
}
.admin-user-table {
  table {
    table-layout: fixed;
    tbody {
      td {
        // width: 10px;
        span {
          width: 100%;

          word-wrap: break-word;
        }
      }
    }
  }
}
