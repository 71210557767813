@import "./_variables";

.d-flex {
  display: flex;
}

.p-fixed {
  position: fixed;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.gap-10 {
  gap: 10px;
}

.p {
  &-x {
    &-1 {
      padding-left: 10px;
      padding-right: 10px;
    }
    &-2 {
      padding-left: 20px;
      padding-right: 20px;
    }
    &-3 {
      padding-left: 30px;
      padding-right: 30px;
    }
    &-4 {
      padding: 40px;
    }
  }
  &-y {
    &-1 {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    &-2 {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    &-3 {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    &-4 {
      padding: 40px;
    }
    &-5 {
      padding: auto 50px;
    }
  }
}

.primary-color {
  color: $primary-color !important;
}

.error-message {
  color: $white-color;
  font-size: 14px;
  background-color: $yellow-color;
  padding: 10px;
  border-radius: 2px;
}
