@import "components/ui/__variables";

.indicator-input {
  color: #1d2433;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Raleway;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  padding: 0px 8px;
  justify-content: space-between;
  align-items: center;

  &__block {
    display: flex;
    align-items: center;
    .man_switch {
      margin-right: 4rem;
    }
    .aut_switch {
      margin-left: 1.8rem;
    }
  }

  svg {
    margin-top: 8px;
    cursor: pointer;
  }
  &.use-grid {
    display: grid !important;
    justify-content: unset;
    grid-template-columns: 39% 39% 39%;
    @media screen and (max-width: $medium-screen) {
      grid-template-columns: 35% 35% 35%;
    }
  }

  p {
    color: #1d2433;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Raleway;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .text-input {
    width: 100px !important;
    border: none !important;
  }
  input {
    width: 100% !important;
    border: none !important;
  }

  .input {
    padding: 2px 1px !important;
  }

  .date-picker-input {
    width: 120px !important;
    border: none !important;
    i {
      display: none !important;
    }
    div {
      border: none !important;
    }
  }

  .custom-select {
    width: 150px !important;
    margin-left: -2rem;
     .active {
      background: rgba(212, 248, 211, 0.6) !important;
    }
    .inactive {
      background: rgba(251, 231, 233, 0.6) !important;
    }
  }
}
