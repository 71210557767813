@import "components/ui/__variables";

.po-tasks {
  margin-top: 16px;
  &__header {
    display: flex;
    border-bottom: 1px solid #f5f6fa;
    background: #edf7fd;
    display: flex;
    // height: 54px !important;
    padding: 20px 16px 16px 16px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;

    .git_icon {
      background-color: #ffffff;
      padding: 0.8rem;
      cursor: pointer;
    }

    .blocker_button {
      background-color: #f91b0d !important;
      color: #ffffff;
      border: none;
      border-radius: 20px;
      font-size: 14px;
      :hover {
        color: #ffffff !important;
      }
    }

    @media screen and (max-width: $medium-screen) {
      display: block;
    }

    div {
      display: flex;
      gap: 7px;
      @media screen and (max-width: $medium-screen) {
        gap: 12px;
      }
    }
    h3 {
      color: rgba(7, 25, 57, 0.87);
      font-family: Raleway;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-top: 0;
      margin-bottom: 0;
      @media screen and (max-width: $medium-screen) {
        margin-bottom: 12px;
      }
    }
    .search-input {
      height: 38px !important;
      display: flex;
      width: 238px !important;

      color: #7e848e !important;
      font-family: DM Sans;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    button {
      display: flex;
      width: 103px !important;
      height: 40px !important;
      padding: 6px 12px !important;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      color: var(--white, #fff);
      font-family: DM Sans;
      font-size: 10px !important;
      font-style: normal;
      font-weight: 500 !important;
      line-height: normal;
    }
  }
}

.update_modal {
  margin-top: 1rem;
}
.git_url {
  margin-right: 1rem;
}
