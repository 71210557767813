@import "components/ui/__variables";

.help-support {
  background-color: $white-color;
  padding: 35px;
  @media screen and (max-width: $medium-screen) {
    padding: 20px;
  }
  h3 {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 0;
  }
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    justify-content: space-between;
    @media screen and (max-width: $medium-screen) {
      grid-template-columns: 1fr;
    }
    p {
      color: #000;
      font-family: Raleway;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.36px;
      margin-bottom: 13px;
      margin-top: 50px;
      @media screen and (max-width: $medium-screen) {
        margin-top: 20px;
      }
    }
  }

  &__questions {
    display: grid;
    gap: 16px;
    padding: 0px 16px;
    margin-top: 16px;
    @media screen and (max-width: $medium-screen) {
      padding: 0px 0px;
    }
  }

  .search-input {
    width: 100%;
  }

  &__question {
    // min-height: 59px;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    // margin-top: 29px;
    background-color: #008ee21a;
    padding: 0px 43px;
    padding-top: 20px;
    padding-bottom: 20px;

    @media screen and (max-width: $medium-screen) {
      padding: 0 20px;
      padding-top: 12px;
      padding-bottom: 12px;
    }

    svg {
      path {
        fill: $black-color;
      }
    }

    cursor: pointer;
    div {
      width: 100%;
    }
    .d-flex {
      display: flex;
      justify-content: space-between !important;
    }
    span {
      color: #000;
      font-family: Raleway;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.36px;
    }
    p {
      margin-top: 8px;
      margin-bottom: 0;
      color: $gray-muted-variant-color;
      font-family: Raleway;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.36px;
    }
  }

  &__image {
    text-align: right;
    @media screen and (max-width: $medium-screen) {
      display: none;
    }
  }

  &__socials {
    display: flex;
    gap: 27px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    margin-top: 50px;
  }
}
