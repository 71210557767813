.integration_section {
  background-color: #ffffff;
  width: 100%;
  color: #000000;
}

.integration_section_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d9dfec;
  height: 50px;
  padding: 0 1.5rem;
  cursor: pointer;
}

.integration_section_header h6 {
  color: #000000;
  font-size: 20px;
  font-weight: 500;
}

.integration_items {
  padding: 2rem 1rem 3rem 1rem;
  min-height: 300px;
  max-height: 700px;
  overflow: auto;
}

.integration_items_body {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: "480px") {
    width: 100%;
    margin-left: 0;
  }
}

.integration_card {
  width: 265px;
  height: 220px;
  padding: 1rem 2rem 2rem 2rem;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 5px 5px 5px 0 #ccc;
  margin-right: 2rem;
  margin-bottom: 2rem;
  @media screen and (max-width: "480px") {
    width: 100%;
    margin-left: 0;
  }
}

.integration_card_top {
  border-bottom: 1px solid #ccc;
  padding-bottom: 0.7rem;
}

.integration_card_img_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.integration_card_img_div img {
  width: 50px;
}

.integration_card_content {
  margin-top: -1rem;
}

.integration_card_content h2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.integration_card_content p {
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}

.footer {
  margin-top: -0.5rem;
}

.footer p {
  font-size: 16px;
  font-weight: 400;
}

.btn_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.integration_connect_button {
  border: 1px solid #008EE2;
  color: #008EE2;
  font-size: 12px;
  font-weight: 400;
  background: none;
  padding: 0.5rem 2rem;
  border-radius: 3px;
  margin-top: 0.5rem;
  cursor: pointer;
}

.integration_connected_button {
  background-color:  #008EE2;
  border: none;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  padding: 0.2rem 1rem;
  border-radius: 5px;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}

.integration_disconnect_button {
  border: 1px solid #008EE2;
  color:  #008EE2;
  font-size: 12px;
  font-weight: 400;
  background: none;
  padding: 0.25rem 1rem;
  border-radius: 3px;
  margin-top: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.connected_icon {
  margin-left: 0.5rem;
  margin-top: 0.1rem;
}
.integration_icon {
  height: 50px;
  width: 100%;
}

.disconnect_icon {
  margin-right: 0.5rem;
  margin-top: 0.1rem;
}

.integration_items_header {
  display: flex;
  padding-top: 0;
  padding-right: 10px;
  padding-left: 0;
  border-bottom: 1px solid #e1e2e6;
  margin-bottom: 3rem;
  width: 400px;
}

.inactive_p {
  padding-top: 0;
  padding-right: 20px;
  padding-bottom: 0.5rem;
  padding-left: 20px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.active_p {
  padding-top: 0;
  padding-right: 20px;
  padding-bottom: 0.5rem;
  padding-left: 20px;
  border-bottom: 1px solid #008ee2;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.drop_toggle {
  border: none !important;
  background: none;
  cursor: pointer;
}

.drop_menu {
  width: 100px;
}

.drop_divider {
  margin: 0.2rem -1rem !important;
}

.drop_item {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
}

.modal_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.title_icon {
  cursor: pointer;
  margin-top: 0.5rem;
}

.view_children {
  margin-top: 2rem;
}

.view_child {
  padding: 0.7rem 1rem;
  margin-bottom: 1rem;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 2px #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view_child p {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
}

.connect_modal {
  margin-top: 1rem;
}

.btn_connect {
  background-color: #002c61 !important;
  border-radius: 0 !important;
}

.modal_delete_title {
  margin-top: 2rem;
}

.modal_delete_title p {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.modal_delete_btns {
  margin: 3rem 0 2.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 7rem;
  width: 50%;
  @media screen and (max-width: "480px") {
    width: 100%;
    margin-left: 0;
  }
}

.modal_delete_btns_ {
  margin: 3rem 0 2.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 7.5rem;
  width: 100%;
}

.yes_btn {
  background-color: #ea0d0d !important;
  border-radius: 5px !important;
  padding: 1rem 1.5rem !important;
  color: #ffffff !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
}

.yes_btn_ {
  background-color: #ea0d0d !important;
  border-radius: 5px !important;
  padding: 1rem 1.5rem !important;
  color: #ffffff !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  margin-right: 2rem;
}

.no_btn {
  border-radius: 5px;
  padding: 1rem 1.5rem;
  color: #000000;
  border: 1px solid #000000;
  background: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.drop-zone {
  display: block;
  margin: 0 auto;
  width: 50%;
  border: 5px dashed rgb(141, 140, 140);
  border-radius: 10px;
  margin-top: 2rem;
  padding: 1.5rem 1rem;
}

.drop-zone p {
  text-align: center;
  color: 00000;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}

.drop_img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
}

.drop-zone label {
  color: #008ee2;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}

.img_div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: "300px";
  position: relative;
}

.img_div_loader {
  position: absolute;
}

.img_div img {
  border-radius: 30px;
}

.connect_title {
  font-size: 20px;
  font-weight: 400;
}

.field_name {
  text-align: center;
  font-size: 12px;
  text-transform: capitalize;
}
