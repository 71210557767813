@import "components/ui/__variables";

.settings {
  background-color: $white-color;
  padding: 35px;

  @media screen and (max-width: $medium-screen) {
    padding: 20px;
  }

  h3 {
    color: $black-color;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.36px;
    margin-bottom: 0;
    margin-top: 0;
  }

  a {
    text-decoration: none;
  }

  &__form {
    display: grid;
    gap: 10px;
    margin-top: 50px;
    width: 50%;
    @media screen and (max-width: $medium-screen) {
      width: 100%;
      margin-top: 20px;
    }
  }

  &__github--button {
    background: linear-gradient(0deg, #ebebeb, #ebebeb),
      linear-gradient(0deg, #fafafa, #fafafa) !important;
    border: 1px solid #ebebeb !important;
    height: 56px !important;
    padding: 10px !important;
    gap: 16px !important;
    font-family: Raleway;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 19px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    color: $black-color !important;
    svg {
      width: 100% !important;
    }
  }
}
