@import "components/ui/__variables";

.layout-sidebar {
  // width: 180px;

  height: 100vh;

  flex-shrink: 0;

  background-color: $secondary-color;

  padding: 50px 26px;

  position: fixed;
  z-index: 1;

  top: 96px;

  overflow: scroll;

  @media screen and (max-width: $large-screen) {
    // display: none;
    width: 200px;
    padding: 50px 20px;
  }

  @media screen and (max-width: $large-screen) {
    display: none !important;
  }
}

.layout-mobile-sidebar {
  @media screen and (min-width: $large-screen) {
    display: none !important;
  }
  section {
    background-color: $secondary-color;

    padding: 25px 26px;

    z-index: 1;

    height: 100%;

    overflow: scroll;
  }
}

.switch {
  position: fixed;
  bottom: 5%;
  left: 2%;
}


.switch_ {
  position: fixed;
  bottom: 15%;
  left: 10%;
}

.switch_label {
  color: #FFFFFF;
  font-weight: bold;
  margin-left: 0.5rem;
}

.switch_item {
  display: flex;
  justify-content: center;
  align-items: center;
}
