@import "../_variables";

.file-input {
  width: fit-content;
  cursor: pointer;
  label {
    padding: 10px;
    background: transparent;
    display: table;
    color: #fff;
    top: 0px;
    background: $white-primary-diluted-color;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: $black-color;
    font-size: 12px;
    font-family: DM Sans;
  }
  label:hover {
    border: 1px solid $primary-color;
    color: $primary-color;
    transition: 0.5s;
  }
  .label-invert {
    border: 1px solid $primary-color;
    color: $primary-color;
  }
  .label-invert:hover {
    border: 1px solid $white-muted-color;
    color: $white-primary-diluted-color;
  }

  input[type="file"] {
    display: none;
  }

  &-error {
    color: $cancel-color;
    font-size: 0.8rem;
  }
}
