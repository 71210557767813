@import "components/ui/__variables";

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.layout {
  width: 100%;

  height: 100vh;

  transition: 0.3s opacity;

  background-color: $white-primary-variant;

  &__page-header {
    position: fixed;
    background-color: $white-primary-variant;

    padding: 27px 30px 0px 30px;

    left: 265px;
    right: 0px;
    top: 96px;
    z-index: 1;

    @media screen and (max-width: $large-screen) {
      left: 0px;
      padding-top: 0px;
    }
  }

  &__container {
    display: flex;
  }

  main {
    // height: 100%;

    // min-height: 100vh;

    // width: 100%;

    animation: fadeIn 5s;

    padding: 27px 30px 0px 30px;

    left: 265px;

    right: 0px;

    top: 96px;

    position: absolute;

    position: fixed;

    overflow: scroll;

    top: 260px;

    bottom: 0;

    z-index: 100;
    // padding: 57px 31px;
    background-color: $white-primary-variant;

    @media screen and (max-width: $large-screen) {
      left: 0px;

      // overflow-y: unset;

      top: 300px;

      padding-bottom: 100px;
    }

    &.push-up {
      top: 250px !important;
      @media screen and (max-width: $large-screen) {
        top: 200px !important;
      }
    }

    &.project {
      top: 180px !important;
      @media screen and (max-width: $large-screen) {
        top: 180px !important;
      }
    }

    &.dashboard {
      top: 180px !important;
      @media screen and (max-width: $large-screen) {
        top: 120px !important;
      }
    }
    
    &.help {
      top: 180px !important;

      @media screen and (max-width: $large-screen) {
        top: 120px !important;
      }
    }
  }
}
