@import "components/ui/Main";

.signup-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signup-profile {
  width: 100%;

  &__upload {
    display: grid;
    gap: 10px;
    margin-bottom: 50px;
  }

  &__inputs {
    display: grid;

    gap: 10px;
  }

  button {
    margin-top: 10px;

    margin-bottom: 20px;
  }
  .input_item {
    margin-bottom: 1rem;
  }
}
