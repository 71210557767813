@import "../../_variables";

.progress-loader {
  $bg: rgba($white-muted-color, 0.2);

  z-index: 10;
  width: 100%;
  min-height: 15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $bg;

  &__bar {
    width: 150px;
    height: 45px;
    position: relative;

    p {
      color: $gray-color;
      animation: text 1s ease both infinite;
      font-size: 1rem;
      letter-spacing: 1px;
      margin-bottom: 1rem;

      @keyframes text {
        0% {
          letter-spacing: 1px;
          transform: translateX(0px);
        }

        40% {
          letter-spacing: 2px;
          transform: translateX(26px);
        }

        80% {
          letter-spacing: 1px;
          transform: translateX(32px);
        }

        90% {
          letter-spacing: 2px;
          transform: translateX(0px);
        }

        100% {
          letter-spacing: 1px;
          transform: translateX(0px);
        }
      }
    }
    span {
      background-color: $gray-color;
      border-radius: 50px;
      display: block;
      height: 0.7rem;
      width: 3rem;
      bottom: 0;
      position: absolute;
      transform: translateX(64px);
      animation: loading 1s linear 0ms infinite;

      &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background-color: $primary-color;
        border-radius: inherit;
        animation: loading2 1s linear 0ms infinite;
      }

      @keyframes loading {
        0% {
          width: 3rem;
          transform: translateX(0px);
        }

        40% {
          width: 100%;
          transform: translateX(0px);
        }

        80% {
          width: 3rem;
          transform: translateX(64px);
        }

        90% {
          width: 100%;
          transform: translateX(0px);
        }

        100% {
          width: 3rem;
          transform: translateX(0px);
        }
      }
      @keyframes loading2 {
        0% {
          transform: translateX(0px);
          width: 3rem;
        }

        40% {
          transform: translateX(0%);
          width: 80%;
        }

        80% {
          width: 100%;
          transform: translateX(0px);
        }

        90% {
          width: 80%;
          transform: translateX(15px);
        }
        100% {
          transform: translateX(0px);
          width: 3rem;
        }
      }
    }
  }
}
